<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
              align-h="between"
              align-v="center"
              class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Mantenimientos
              </h3>
            </b-col>
            <b-col cols="auto">
              <div class="d-flex align-items-center" v-if="currentUser.roles[0].name === 'super_admin'">
                <b-link :to="{ name: 'createMaintenance' }">
                  <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                    {{ '+ Añadir mantenimiento' }}
                  </b-button>
                </b-link>
              </div>
            </b-col>
          </b-row>
          <hr class="m-0" />
          <!-- filtros -->
          <div class="p-1">
            <div class="col-12">
              <b-row>
                <b-col class="col-11"></b-col>
                <b-col class="col-1 text-right">
                  <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                    <feather-icon icon="FilterIcon" size="20" />
                  </span>
                </b-col>
              </b-row>
            </div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-status">
                      <v-select v-model="status" label="name" :filterable="false" :searchable="false"
                        :options="selectStatus" :placeholder="'Filtrar por estado'" />
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <flat-pickr v-model="date" name="date" class="form-control" :config="dateConfig"
                      :placeholder="'Filtrar por fecha'" />
                  </b-col>

                  <b-col sm="12" md="4">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-form-input v-model="searchTerm" :placeholder="'Nombre'" type="text"
                        class="d-inline-block" />
                      <b-button class="ml-1" @click="handleSearch" variant="primary">
                        {{ $t("Buscar") }}
                      </b-button>
                    </div>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-client">
                      <BaseSelect
                        :resource="'/projects'"
                        @input="project = $event"
                        :value="project"
                        :label="'name'"
                        :placeholder="'Filtrar por obra'"
                        :clearable="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-client">
                      <SelectClients v-model="client" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <!-- tabla -->
          <b-table hover responsive :items="items" :fields="columns" class="table-white">
            <template #cell(client)="data">
              <b-link :to="{ name: 'viewClient', params: { id: data.item.client.id } }" class="link">
                {{ data.item.client.name }}
              </b-link>
            </template>
            <template #cell(name)="data">
              <b-link :to="{ name: 'viewMaintenance', params: { id: data.item.id } }" class="link">
                {{ data.item.name }}
              </b-link>
            </template>
            <template #cell(project)="data">
              <b-link :to="{ name: 'viewProject', params: { id: data.item.project.id } }" class="link">
                {{ data.item.project.name }}
              </b-link>
            </template>
            <template #cell(frequency_type)="data">
              <span>{{ frequencyText[data.item.frequency_type] }}</span>
            </template>
            <!-- responsable -->
            <template #cell(user)="data">
              <b-link v-if="data.item.user" :to="{ name: 'viewUser', params: { id: data.item.user.id } }" class="link">
                <span>{{ data.item.user.name }} {{ data.item.user.surname }}</span>
              </b-link>
            </template>
            <!-- dateColumn -->
            <template #cell(date)="data">
              <span :class="(data.item.expired) ? 'text-red' : ''">{{ dateColumn(data.item.date) }}</span>
            </template>
            <template #cell(status)="data">
              <div class="d-flex">
                <b-badge v-if="data.item.status" :variant="statusMaintenanceVariants[data.item.status.alias]">
                  {{ data.item.status.name }}
                </b-badge>
                <b-badge v-if="(data.item.inicidence == 1)" variant="light-warning" style="margin-left: 5px;">
                  <feather-icon icon="AlertTriangleIcon" size="22" />
                </b-badge>
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link :to="{ name: 'viewMaintenance', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                  :to="{ name: 'editMaintenance', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark">
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span v-if="currentUser.roles[0].name === 'super_admin'" class="d-inline-block px-50 text-danger"
                  @click="deleteMaintenance(data.item.id, data.item.name)">
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                {{ 'Por página' }}:
              </span>
              <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
              <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @change="handleChangePage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BFormGroup,
  BCol, BBadge,
  BRow, BLink, BInputGroup, BInputGroupPrepend, BCollapse
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { config } from "@/shared/app.config"
import { TimestampToFlatPickrWithTime, FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import flatPickr from 'vue-flatpickr-component'
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import vSelect from "vue-select";
import SelectClients from "@/components/clients/select/ClientsSelect.vue";

export default {
  components: {
    SelectClients,
    BBadge,
    BCard,
    BTable,
    BFormGroup,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BCollapse,
    vSelect,
    flatPickr,
    BInputGroupPrepend,
    BaseSelect
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Cliente',
          key: 'client',
          // key: 'id',
        },
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Obra',
          key: 'project',
        },
        {
          label: 'Frecuencia',
          key: 'frequency_type',
        },

        {
          label: 'Técnico',
          key: 'user',
        },
        {
          label: 'Fecha',
          key: 'date',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      visibleFilter: false,
      frequencyText: config.frequencyText,
      statusMaintenanceVariants: config.statusMaintenanceVariants,
      status: '',
      date: '',
      dateConfig: {
        mode: "range"
      },
      date_ini: '',
      date_fin: '',
      project: '',
      client: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      items: 'maintenances/getItems',
      totalItems: 'maintenances/getTotalItems',
      selectStatus: 'orders/getSelectStatus',
    })
  },
  methods: {
    ...mapActions({
      getListMaintenances: 'maintenances/getListMaintenances',
      getSelectStatus: 'orders/getSelectStatus',
      delete: 'maintenances/delete',
    }),
    dateColumn(time) {
      return TimestampToFlatPickrWithTime(time)
    },
    async chargeData() {
      let status = this.status ? this.status.id : ''

      await this.getListMaintenances({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        status_id: status,
        date_ini: this.date_ini,
        date_fin: this.date_fin,
        id_client: this.client?.id || '',
        project_id: this.project?.id || '',
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteMaintenance(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
  watch: {
    date() {

      let range = this.date.split(' a ')

      this.date_ini = FlatPickrToTimestamp(range[0]);
      if (range.length > 1) {
        this.date_fin = FlatPickrToTimestamp(range[1]);
      } else {
        this.date_fin = '';
      }

      this.currentPage = 1;
      this.chargeData();

      // console.log('fechas: ', this.date_ini, this.date_fin)
    },
    sortBy() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortDesc() {
      this.currentPage = 1;
      this.chargeData();
    },
    orderby() {
      this.currentPage = 1;
      this.chargeData();
    },
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
    client() {
      this.currentPage = 1;
      this.chargeData();
    },
    project() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  async created() {
    await this.chargeData()
    await this.getSelectStatus({
      type: "maintenance"
    })
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.text-red {
  color: red !important
}
</style>
